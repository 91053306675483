import { combineReducers } from "@reduxjs/toolkit";
import globalSlice from "./appStore"; // 可以引入各种 reducer
import appointmentSlice from "./appointmentStore";

const rootReducers = combineReducers({
  global: globalSlice, // 这里通过 MAP 形式，自定义不同 reducer 的“命名空间”
  // ... 可以在这里扩展添加任意的 reducer
  appoint: appointmentSlice,
});

// 默认导出，给 configureStore 消费
export default rootReducers;
