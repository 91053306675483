import request from "@/request";

// 用户角色列表
export const employeeRoleList = (data) => {
    return request({
        url: "/fyys/admin/systemRole/list",
        method: "post",
        data,
    });
};
// 用户角色保存
export const employeeRoleSave = (data) => {
    return request({
        url: "/fyys/admin/systemRole/save",
        method: "post",
        data
    });
};

// 用户角色修改
export const employeeRoleUpdate = (data) => {
    return request({
        url: "/fyys/admin/systemRole/update",
        method: "post",
        data
    });
};

// 角色关联菜单
export const roleMenuList = (data) => {
    return request({
        url: "/fyys/admin/systemRole/roleMenuList",
        method: "post",
        data
    });
};

// 树形菜单
export const menuTree = (data) => {
    return request({
        url: "/fyys/admin/systemMenu/menuTree",
        method: "post",
        data
    });
};

export const menuSave=(data)=>{
    return request({
        url: "/fyys/admin/systemMenu/save",
        method: "post",
        data
    });
}

export const menuUpdate=(data)=>{
    return request({
        url: "/fyys/admin/systemMenu/update",
        method: "post",
        data
    });
}

// 登录用户的菜单权限
export const permsList=(data={})=>{
    return request({
        url: "/fyys/admin/systemMenu/permsList",
        method: "post",
        data
    });
}
