import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from ".."; // 在 store/index.ts 中声明的类型
import defaultSettings from "../../settings.json";
const getUserInfo = () => {
  const userInfo = JSON.parse(
    localStorage?.getItem("userInfo")
      ? localStorage?.getItem("userInfo")
      : JSON.stringify("")
  );
  return userInfo
    ? userInfo
    : {
        permissions: {}
      };
};
export interface GlobalState {
  settings?: typeof defaultSettings;
  userInfo?: {
    name?: string;
    avatar?: string;
    job?: string;
    organization?: string;
    location?: string;
    email?: string;
    permissions?: Record<string, string[]>;
    avatarUrl?: string;
  };
  permissionsBtn?:object,
  userLoading?: boolean;
  realName?: string;
  menu?: Array<any>;
  privilege?: object;
  notAllowJump?: boolean;
  menuCollapsed?: boolean;
}
const initialState: GlobalState = {
  settings: defaultSettings,
  userInfo: getUserInfo(),
  realName: "",
  menu: [],
  privilege: {},
  notAllowJump: false,
  menuCollapsed: false,
  permissionsBtn:{}
};

const globalSlice = createSlice({
  name: "global", // 生成 Action type 的前缀，例如：counter/increment
  initialState: initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<GlobalState>) => {
      const { settings } = action.payload;
      state.settings = settings;
    },
    updateUserInfo: (state, action: PayloadAction<GlobalState>) => {
      const { userInfo = initialState.userInfo, userLoading } = action.payload;
      return { ...state, userLoading, userInfo };
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      localStorage.setItem('userInfo', JSON.stringify(action.payload))
      state.userInfo = action.payload;
    },
    loginVo: (state, action: PayloadAction<GlobalState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setPermissions:(state,action:PayloadAction<any>)=>{
      state.userInfo =Object.assign(state.userInfo,{permissions:action.payload});
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    },
    setPermissionsBtn:(state, action: PayloadAction<GlobalState>)=>{
      state.permissionsBtn=action.payload
    },
    setBreadcrumbVisable: (state, action: PayloadAction<boolean>) => {
      state.settings.breadcrumb = action.payload;
    },
    setNotAllowJump: (state, action: PayloadAction<boolean>) => {
      state.notAllowJump = action.payload;
    },
    setMenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.menuCollapsed = action.payload;
    },
    logoutAction: (state, action) => {
      localStorage.setItem("userInfo", "");
      localStorage.setItem("token", "");
      localStorage.setItem("userStatus", "");
      state.userInfo = {};
    },
  },
});

export const {
  updateSettings,
  updateUserInfo,
  loginVo,
  setBreadcrumbVisable,
  setNotAllowJump,
  setMenuCollapsed,
  setUserInfo,
  logoutAction,
  setPermissions,
  setPermissionsBtn
} = globalSlice.actions;
// Selector，作为 useSelector 读取数据的函数参数
export const globalSelector = (state: RootState) => state.global;

// Reducer，真正执行修改 state 的纯函数
export default globalSlice.reducer;
