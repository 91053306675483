import React, { useState, useRef, useMemo, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Spin, Modal } from "@arco-design/web-react";
import cs from "classnames";
import {
  IconDashboard,
  IconTag,
  IconMenuFold,
  IconMenuUnfold,
  IconOrderedList,
} from "@arco-design/web-react/icon";
import { useSelector, useDispatch } from "react-redux";
import qs from "query-string";
import NProgress from "nprogress";
import { globalSelector, setNotAllowJump, setMenuCollapsed, setPermissions, setPermissionsBtn } from "./store/reducers/appStore";
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import useRoute, { IRoute } from "@/routes";
import { Prompt } from "react-router"
import useLocale from "./utils/useLocale";
import getUrlParams from "./utils/getUrlParams";
import lazyload from "./utils/lazyload";
import { GlobalState } from "./store/reducers/appStore";
import styles from "./style/layout.module.less";
import { permsList } from "./api/auth";

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

const Sider = Layout.Sider;
const Content = Layout.Content;

function getIconFromKey(key) {
  switch (key) {
    case "order":
      return <IconOrderedList></IconOrderedList>;
    case "appointment":
      return <IconOrderedList></IconOrderedList>;
    case "dashboard":
      return <IconDashboard className={styles.icon} />;
    case "example":
      return <IconTag className={styles.icon} />;
    default:
      if (key.indexOf('/') != -1) {
        return <div className={styles["icon-empty"]} />;
      }
      return <IconOrderedList></IconOrderedList>
  }
}

function getFlattenRoutes(routes) {
  const res = [];
  function travel(_routes) {
    _routes.forEach((route) => {
      const visibleChildren = (route.children || []).filter(
        (child) => !child.ignore
      );
      if (route.key && (!route.children || !visibleChildren.length)) {
        try {
          route.component = lazyload(() => import(`./pages/${route.key}`));
          res.push(route);
        } catch (e) {
          console.error(e);
        }
      }
      if (route.children && route.children.length) {
        travel(route.children);
      }
    });
  }
  travel(routes);
  return res;
}

function PageLayout(props) {
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();


  const urlParams = getUrlParams();
  const history = useHistory();
  const pathname = history.location.pathname;
  const currentComponent = qs.parseUrl(pathname).url.slice(1);
  const locale = useLocale();
  const { settings, userLoading, userInfo, notAllowJump, menuCollapsed } = useSelector(globalSelector);


  const [routes, defaultRoute] = useRoute(userInfo?.permissions);
  const defaultSelectedKeys = [currentComponent || defaultRoute];
  const paths = (currentComponent || defaultRoute).split("/");
  const defaultOpenKeys = paths.slice(0, paths.length - 1);

  const [breadcrumb, setBreadCrumb] = useState([]);
  // const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] =
    useState<string[]>(defaultSelectedKeys);
  const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys);

  const routeMap = useRef<Map<string, React.ReactNode[]>>(new Map());
  const menuMap = useRef<
    Map<string, { menuItem?: boolean; subMenu?: boolean }>
  >(new Map());

  const navbarHeight = 60;
  const menuWidth = menuCollapsed ? 48 : settings.menuWidth;

  const showNavbar = settings.navbar && urlParams.navbar !== false;
  const showMenu = settings.menu && urlParams.menu !== false;
  const showFooter = settings.footer && urlParams.footer !== false;
  const showBreadcrumb = settings.breadcrumb;

  const flattenRoutes = useMemo(() => {
    const _rules = getFlattenRoutes(routes) || [];
    return _rules;
  }, [routes]);

  function renderRoutes(locale) {
    routeMap.current.clear();
    return function travel(_routes: IRoute[], level, parentNode = []) {
      return _routes.map((route) => {
        const { breadcrumb = true, ignore } = route;
        const iconDom = getIconFromKey(route.key);
        const titleDom = (
          <>
            {iconDom} {locale[route.name] || route.name}
          </>
        );
        routeMap.current.set(
          `/${route.key}`,
          breadcrumb ? [...parentNode, { key: `#/${route.key}`, name: route.name }] : []
        );

        const visibleChildren = (route.children || []).filter((child) => {
          const { ignore, breadcrumb = true } = child;
          if (ignore || route.ignore) {
            routeMap.current.set(
              `/${child.key}`,
              breadcrumb ? [...parentNode, { key: `#/${route.key}`, name: route.name }, { key: `#/${child.key}`, name: child.name }] : []
            );
          }

          return !ignore;
        });

        if (ignore) {
          return "";
        }
        if (visibleChildren.length) {
          menuMap.current.set(route.key, { subMenu: true });
          return (
            <SubMenu key={route.key} title={titleDom}>
              {travel(visibleChildren, level + 1, [...parentNode, { key: `#/${route.key}`, name: route.name }])}
            </SubMenu>
          );
        }
        menuMap.current.set(route.key, { menuItem: true });
        return <MenuItem key={route.key}>{titleDom}</MenuItem>;
      });
    };
  }

  function onClickMenuItem(key) {
    const currentRoute = flattenRoutes.find((r) => r.key === key);
    const component = currentRoute.component;
    const preload = component.preload();
    NProgress.start();
    preload.then(() => {
      history.push(currentRoute.path ? currentRoute.path : `/${key}`);
      NProgress.done();
    });
  }

  function toggleCollapse() {
    // setCollapsed((collapsed) => !collapsed);
    dispatch(setMenuCollapsed(!menuCollapsed));
  }

  const paddingLeft = showMenu ? { paddingLeft: menuWidth } : {};
  const paddingTop = showNavbar ? { paddingTop: navbarHeight } : {};
  const paddingStyle = { ...paddingLeft, ...paddingTop };

  function updateMenuStatus() {
    const pathKeys = pathname.split("/");
    const newSelectedKeys: string[] = [];
    const newOpenKeys: string[] = [...openKeys];
    while (pathKeys.length > 0) {
      const currentRouteKey = pathKeys.join("/");
      const menuKey = currentRouteKey.replace(/^\//, "");
      const menuType = menuMap.current.get(menuKey);
      if (menuType && menuType.menuItem) {
        newSelectedKeys.push(menuKey);
      }
      if (menuType && menuType.subMenu && !openKeys.includes(menuKey)) {
        newOpenKeys.push(menuKey);
      }
      pathKeys.pop();
    }
    setSelectedKeys(newSelectedKeys);
    setOpenKeys(newOpenKeys);
  }

  useEffect(() => {
    const routeConfig = routeMap.current.get(pathname);
    setBreadCrumb(routeConfig || []);
    updateMenuStatus();


  }, [pathname]);
  useEffect(() => {
    if (pathname == '/') return;
    permsList().then(res => {
      const datas = {}
      const btnDatas = {}
      for (const item of res.data ?? []) {
        if (item.indexOf(':')>-1) {
          btnDatas[item] = true
        } else {
          datas[item] = ['*']
        }

      }
      dispatch(setPermissions(datas))
      dispatch(setPermissionsBtn(btnDatas))
    })
  }, [])
  function handleRouter() {
    const config = {
      title: '提示',
      content: '退出当前数据会丢失',
      onOk: () => { dispatch(setNotAllowJump(false)); history.goBack(); },
      onCancel: () => { dispatch(setNotAllowJump(true)); }
    };
    modal.confirm(config);
    return false;
  }

  return (
    <Layout className={styles.layout}>
      {contextHolder}
      <Prompt when={notAllowJump} message={handleRouter}></Prompt>
      <div
        className={cs(styles["layout-navbar"], {
          [styles["layout-navbar-hidden"]]: !showNavbar,
        })}
      >
        <Navbar show={showNavbar} {...props} />
      </div>
      {userLoading ? (
        <Spin className={styles["spin"]} />
      ) : (
        <Layout>
          {showMenu && (
            <Sider
              className={styles["layout-sider"]}
              width={menuWidth}
              collapsed={menuCollapsed}
              onCollapse={toggleCollapse}
              trigger={null}
              collapsible
              breakpoint="xl"
              style={paddingTop}
            >
              <div className={styles["menu-wrapper"]}>
                <Menu
                  collapse={menuCollapsed}
                  onClickMenuItem={onClickMenuItem}
                  selectedKeys={selectedKeys}
                  openKeys={openKeys}
                  onClickSubMenu={(_, openKeys) => setOpenKeys(openKeys)}
                >
                  {renderRoutes(locale)(routes, 1)}
                </Menu>
              </div>
              <div className={styles["collapse-btn"]} onClick={toggleCollapse}>
                {menuCollapsed ? <IconMenuUnfold /> : <IconMenuFold />}
              </div>
            </Sider>
          )}
          <Layout className={styles["layout-content"]} style={paddingStyle}>
            <div className={styles["layout-content-wrapper"]}>
              {showBreadcrumb && !!breadcrumb.length && (
                <div className={styles["layout-breadcrumb"]}>
                  <Breadcrumb>
                    {breadcrumb.map((node, index) => (
                      [0, breadcrumb.length - 1].includes(index) ? <Breadcrumb.Item key={index} >
                        {typeof node.name === "string" ? locale[node.name] || node.name : node.name}
                      </Breadcrumb.Item> :
                        <Breadcrumb.Item key={index} href={node.key}>
                          {typeof node.name === "string" ? locale[node.name] || node.name : node.name}
                        </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </div>
              )}
              <Content>
                <Switch>
                  {flattenRoutes.map((route, index) => {
                    return (
                      <Route
                        key={index}
                        exact={route.exact}
                        path={`/${route.key}`}
                        component={route.component}
                      />
                    );
                  })}
                  <Route exact path="/">
                    <Redirect to={`/${defaultRoute}`} />
                  </Route>
                  <Route
                    path="*"
                    component={lazyload(() => import("./pages/exception/403"))}
                  />
                </Switch>
              </Content>
            </div>
            {showFooter && <Footer />}
          </Layout>
        </Layout>
      )}
    </Layout>
  );
}

export default PageLayout;
