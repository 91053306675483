const i18n = {
  "en-US": {
    "menu.order": "shop",
    "menu.appointment": "预约",
    "menu.appointment.billboard": "预约看板",
    "menu.dashboard": "Dashboard",
    "menu.dashboard.workplace": "Workplace",
    "menu.user.info": "User Info",
    "menu.user.setting": "User Setting",
    "menu.user.switchRoles": "Switch Roles",
    "menu.user.role.admin": "Admin",
    "menu.user.role.user": "General User",
    "navbar.logout": "Logout",
    "settings.title": "Settings",
    "settings.themeColor": "Theme Color",
    "settings.content": "Content Setting",
    "settings.navbar": "Navbar",
    "settings.menuWidth": "Menu Width (px)",
    "settings.navbar.theme.toLight": "Click to use light mode",
    "settings.navbar.theme.toDark": "Click to use dark mode",
    "settings.menu": "Menu",
    "settings.footer": "Footer",
    "settings.otherSettings": "Other Settings",
    "settings.colorWeek": "Color Week",
    "settings.alertContent":
      'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
    "settings.copySettings": "Copy Settings",
    "settings.copySettings.message":
      "Copy succeeded, please paste to file src/settings.json.",
    "settings.close": "Close",
    "settings.color.tooltip":
      "10 gradient colors generated according to the theme color",
    "message.tab.title.message": "Message",
    "message.tab.title.notice": "Notice",
    "message.tab.title.todo": "ToDo",
    "message.allRead": "All Read",
    "message.seeMore": "SeeMore",
    "message.empty": "Empty",
    "message.empty.tips": "No Content",
    "message.lang.tips": "Language switch to ",
    "navbar.search.placeholder": "Please search",
  },
  "zh-CN": {
    "menu.shop": "门店",
    "menu.order": "订单",
    "menu.appointment": "预约",
    "menu.appointment.billboard": "预约看板",
    "menu.dashboard": "仪表盘",
    "menu.dashboard.workplace": "工作台",
    "menu.user.info": "用户信息",
    "menu.user.setting": "用户设置",
    "menu.user.switchRoles": "切换角色",
    "menu.user.role.admin": "管理员",
    "menu.user.role.user": "普通用户",
    "navbar.logout": "退出登录",
    "settings.title": "页面配置",
    "settings.themeColor": "主题色",
    "settings.content": "内容区域",
    "settings.navbar": "导航栏",
    "settings.menuWidth": "菜单宽度 (px)",
    "settings.navbar.theme.toLight": "点击切换为亮色模式",
    "settings.navbar.theme.toDark": "点击切换为暗黑模式",
    "settings.menu": "菜单栏",
    "settings.footer": "底部",
    "settings.otherSettings": "其他设置",
    "settings.colorWeek": "色弱模式",
    "settings.alertContent":
      '配置之后仅是临时生效，要想真正作用于项目，点击下方的 "复制配置" 按钮，将配置替换到 settings.json 中即可。',
    "settings.copySettings": "复制配置",
    "settings.copySettings.message":
      "复制成功，请粘贴到 src/settings.json 文件中",
    "settings.close": "关闭",
    "settings.color.tooltip":
      "根据主题颜色生成的 10 个梯度色（将配置复制到项目中，主题色才能对亮色 / 暗黑模式同时生效）",
    "message.tab.title.message": "消息",
    "message.tab.title.notice": "通知",
    "message.tab.title.todo": "待办",
    "message.allRead": "全部已读",
    "message.seeMore": "查看更多",
    "message.empty": "清空",
    "message.empty.tips": "暂无内容",
    "message.lang.tips": "语言切换至 ",
    "navbar.search.placeholder": "输入内容查询",
    "menu.shop.shiftsWork": "排班管理",

    "menu.shop.staffManage": "员工管理",
    "menu.shop.serveManage": "服务区管理",
    "menu.shop.serveManage.add": "新建服务区",
    "menu.shop.serveManage.edit": "编辑服务区",

    "menu.shop.shopManage": "门店管理",
    "menu.shop.shopManage.add": "新建门店",
    "menu.shop.shopManage.edit": "编辑门店",
    "menu.shop.shopManage.detail": "查看门店详情",

    "menu.goods": "商品",
    "menu.goods.serviceItem": "服务项目",
    "menu.goods.serviceItem.add": "新建服务项目",
    "menu.goods.serviceItem.edit": "编辑服务项目",
    "menu.goods.serviceItem.detail": "服务项目详情",
    "menu.goods.serviceItem.makeup.add": "新建发颜配",
    "menu.goods.serviceItem.makeup.edit": "编辑发颜配",
    "menu.goods.serviceItem.makeup.detail": "查看发颜配",
    "menu.goods.debitCard": "储值卡",
    "menu.goods.debitCard.add": "新建储值卡",
    "menu.goods.debitCard.edit": "编辑储值卡",
    "menu.goods.debitCard.detail": "储值卡详情",
    "menu.goods.mall": "商城管理",
    "menu.goods.timecard": "卡项管理",
    "menu.goods.timecard.add": "新建次卡",
    "menu.goods.timecard.edit": "编辑次卡",
    "menu.goods.timecard.detail": "次卡详情",

    "menu.users": "用户",
    "menu.users.userManage": "用户管理",
    "menu.users.userManage.add": "新建用户",
    "menu.users.userManage.edit": "编辑用户",
    "menu.users.userManage.detail": "查看用户",
    "menu.users.userTags": "用户标签",

    "menu.order.card": "储值订单",
    "menu.order.card.detail": "储值订单详情",
    "menu.order.service": "服务订单",
    "menu.order.service.detail": "服务订单详情",
    "menu.order.business": "交易订单",
    "menu.order.business.detail": "交易订单详情",
    "menu.order.cashier": "收银台",

    "menu.auth": "权限管理",
    "menu.auth.role": "角色管理",
    "menu.auth.role.add": "新建角色",
    "menu.auth.role.edit": "编辑角色",
    "menu.auth.role.detail": "查看角色",

    "menu.auth.rember": "成员管理",
    "menu.auth.rember.add": "新建成员",
    "menu.auth.rember.edit": "编辑成员",
    "menu.auth.rember.detail": "查看成员",

    "menu.auth.menu": "菜单管理",
    "menu.auth.menu.add": "新建菜单",
    "menu.auth.menu.edit": "编辑菜单",
    "menu.auth.menu.detail": "查看菜单",

    "menu.tool":'辅助工具',
    "menu.tool.banner":"轮播图",
    "menu.tool.login":"登录协议",
    "menu.tool.simpleEasy":"简易工具",
    
  },
};

export default i18n;
