import { RunTimeConfig } from "@/declaration";

/**
 * 不同环境配置列表
 */
const config: Record<string, RunTimeConfig> = {
  dev: {
    API_HOST: "https://tadmin.crystalbeauty.cn",
  },
  test: {
    API_HOST: "https://tadmin.crystalbeauty.cn",
  },
  uat: {
    API_HOST: "https://tadmin.crystalbeauty.cn",
  },
  prod: {
    API_HOST: "https://tadmin.crystalbeauty.cn",
  },
};
/**
 * 获取运行时的配置，比如 APP_KEY,跨域请求的接口域名
 * @param evn 打包环境
 * @returns 配置信息
 */
const getRuntimeConfig = (evn: string | boolean = "default") => {
  const configEnv = typeof evn === "string" ? evn : "default";
  const result = config[configEnv];
  return result;
};
// daafcdce8fa5cb39624d4a226258a1f2
const geoAmapKey='f1166e1b74c37ab0be09af8b86db6607'//ef9bd4df5d0ed87edc18a675bb1d4a7f' 
export { getRuntimeConfig, config,geoAmapKey };
