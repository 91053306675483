import auth, { AuthParams } from "@/utils/authentication";
import { useEffect, useMemo, useState } from "react";

export type IRoute = AuthParams & {
  name: string;
  key: string;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
  exact?: boolean;
};

export const routes: IRoute[] = [
  {
    name: "menu.appointment",
    key: "appointment",
    requiredPermissions: [
      { resource: "menu.appointment", actions: ["read", "write"] },
    ],
    children: [
      {
        name: "menu.appointment.billboard",
        key: "appointment/billboard",
        requiredPermissions: [
          {
            resource: "menu.appointment.billboard",
            actions: ["read", "write"],
          },
        ],
      },
    ],
  },
  {
    name: "menu.order",
    key: "order",
    requiredPermissions: [
      { resource: "menu.order", actions: ["read", "write"] },
    ],
    children: [
      {
        name: "menu.order.cashier",
        key: "order/cashier",
        requiredPermissions: [
          { resource: "menu.order.cashier", actions: ["read", "write"] },
        ],
      },
      {
        name: "menu.order.service",
        key: "order/service",
        exact: true,
        requiredPermissions: [
          { resource: "menu.order.service", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.order.service.detail",
            key: "order/service/detail",
            ignore: true,
          },
        ],
      },
      {
        name: "menu.order.card",
        key: "order/card",
        exact: true,
        requiredPermissions: [
          { resource: "menu.order.card", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.order.card.detail",
            key: "order/card/detail",
            ignore: true,
          },
        ],
      },
      {
        name: "menu.order.business",
        key: "order/business",
        exact: true,
        requiredPermissions: [
          { resource: "menu.order.business", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.order.business.detail",
            key: "order/business/detail",
            ignore: true,
          },
        ],
      },
    ],
  },
  {
    name: "menu.users",
    key: "users",
    requiredPermissions: [
      { resource: "menu.users", actions: ["read", "write"] },
    ],
    children: [
      {
        name: "menu.users.userManage",
        key: "users/usermanage",
        exact: true,
        requiredPermissions: [
          { resource: "menu.users.userManage", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.users.userManage.add",
            key: "users/usermanage/add",
            ignore: true,
          },
          {
            name: "menu.users.userManage.edit",
            key: "users/usermanage/edit",
            ignore: true,
          },
          {
            name: "menu.users.userManage.detail",
            key: "users/usermanage/detail",
            ignore: true,
          },
        ],
      },
      {
        name: "menu.users.userTags",
        key: "users/usertags",
        requiredPermissions: [
          { resource: "menu.users.userTags", actions: ["read", "write"] },
        ],
      },
    ],
  },
  {
    name: "menu.shop",
    key: "shop",
    requiredPermissions: [
      { resource: "menu.shop", actions: ["read", "write"] },
    ],
    children: [
      {
        name: "menu.shop.shopManage",
        key: "shop/shopmanage",
        exact: true,
        requiredPermissions: [
          { resource: "menu.shop.shopManage", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.shop.shopManage.add",
            key: "shop/shopmanage/add",
            ignore: true,
          },
          {
            name: "menu.shop.shopManage.edit",
            key: "shop/shopmanage/edit",
            ignore: true,
          },
          {
            name: "menu.shop.shopManage.detail",
            key: "shop/shopmanage/detail",
            ignore: true,
          },
        ],
      },
      {
        name: "menu.shop.staffManage",
        key: "shop/staffmanage",
        requiredPermissions: [
          { resource: "menu.shop.staffManage", actions: ["read", "write"] },
        ],
      },
      {
        name: "menu.shop.shiftsWork",
        key: "shop/shiftswork",
        requiredPermissions: [
          { resource: "menu.shop.shiftsWork", actions: ["read", "write"] },
        ],
      },
      {
        name: "menu.shop.serveManage",
        key: "shop/servemanage",
        exact: true,
        requiredPermissions: [
          { resource: "menu.shop.serveManage", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.shop.serveManage.add",
            key: "shop/servemanage/add",
            ignore: true,
          },
          {
            name: "menu.shop.serveManage.edit",
            key: "shop/servemanage/edit",
            ignore: true,
          },
        ],
      },
    ],
  },
  {
    name: "menu.goods",
    key: "goods",
    requiredPermissions: [
      { resource: "menu.goods", actions: ["read", "write"] },
    ],
    children: [
      {
        name: "menu.goods.serviceItem",
        key: "goods/serviceitem",
        exact: true,
        requiredPermissions: [
          { resource: "menu.goods.serviceItem", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.goods.serviceItem.add",
            key: "goods/serviceitem/add",
            ignore: true,
          },
          {
            name: "menu.goods.serviceItem.edit",
            key: "goods/serviceitem/edit",
            ignore: true,
          },
          {
            name: "menu.goods.serviceItem.detail",
            key: "goods/serviceitem/detail",
            ignore: true,
          },
          {
            name: "menu.goods.serviceItem.makeup.add",
            key: "goods/serviceitem/makeup/add",
            ignore: true,
          },
          {
            name: "menu.goods.serviceItem.makeup.edit",
            key: "goods/serviceitem/makeup/edit",
            ignore: true,
          },
          {
            name: "menu.goods.serviceItem.makeup.detail",
            key: "goods/serviceitem/makeup/detail",
            ignore: true,
          },
        ],
      },
      {
        name: "menu.goods.debitCard",
        key: "goods/debitcard",
        exact: true,
        requiredPermissions: [
          { resource: "menu.goods.debitCard", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.goods.debitCard.add",
            key: "goods/debitcard/add",
            ignore: true,
          },
          {
            name: "menu.goods.debitCard.edit",
            key: "goods/debitcard/edit",
            ignore: true,
          },
          {
            name: "menu.goods.debitCard.detail",
            key: "goods/debitcard/detail",
            ignore: true,
          },
        ],
      },
      {
        name: "menu.goods.mall",
        key: "goods/mall",
        exact: true,
        requiredPermissions: [
          { resource: "menu.goods.mall", actions: ["read", "write"] },
        ],
      },
      {
        name: "menu.goods.timecard",
        key: "goods/timecard",
        exact: true,
        requiredPermissions: [
          { resource: "menu.goods.timecard", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.goods.timecard.add",
            key: "goods/timecard/add",
            ignore: true,
          },
          {
            name: "menu.goods.timecard.edit",
            key: "goods/timecard/edit",
            ignore: true,
          },
          {
            name: "menu.goods.timecard.detail",
            key: "goods/timecard/detail",
            ignore: true,
          },
        ],
      },
    ],
  },
  {
    name: "menu.auth",
    key: "auth",
    requiredPermissions: [
      { resource: "menu.auth", actions: ["read", "write"] },
    ],
    children: [
      {
        name: "menu.auth.role",
        key: "auth/role",
        exact: true,
        requiredPermissions: [
          { resource: "menu.auth.role", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.auth.role.add",
            key: "auth/role/add",
            ignore: true,
          },
          {
            name: "menu.auth.role.edit",
            key: "auth/role/edit",
            ignore: true,
          },
          {
            name: "menu.auth.role.detail",
            key: "auth/role/detail",
            ignore: true,
          },
        ],
      },
      {
        name: "menu.auth.rember",
        key: "auth/rember",
        exact: true,
        requiredPermissions: [
          { resource: "menu.auth.rember", actions: ["read", "write"] },
        ],
        children: [],
      },
      {
        name: "menu.auth.menu",
        key: "auth/menu",
        exact: true,
        requiredPermissions: [
          { resource: "menu.auth.menu", actions: ["read", "write"] },
        ],
        children: [
          {
            name: "menu.auth.menu.add",
            key: "auth/menu/add",
            ignore: true,
          },
          {
            name: "menu.auth.menu.edit",
            key: "auth/menu/edit",
            ignore: true,
          },
          {
            name: "menu.auth.menu.detail",
            key: "auth/menu/detail",
            ignore: true,
          },
        ],
      },
    ],
  },

  {
    name: "menu.tool",
    key: "tool",
    requiredPermissions: [
      { resource: "menu.tool", actions: ["read", "write"] },
    ],
    children: [
        {
        name: "menu.tool.simpleEasy",
        key: "tool/simpleeasy",
        exact: true,
        requiredPermissions: [
          { resource: "menu.tool.simpleEasy", actions: ["read", "write"] },
        ]
      },
      // {
      //   name: "menu.tool.banner",
      //   key: "tool/banner",
      //   exact: true,
      //   requiredPermissions: [
      //     { resource: "menu.tool.banner", actions: ["read", "write"] },
      //   ]
      // },
      // {
      //   name: "menu.tool.login",
      //   key: "tool/login",
      //   exact: true,
      //   requiredPermissions: [
      //     { resource: "menu.tool.login", actions: ["read", "write"] },
      //   ]
      // }
    ]
  },
  // {
  //   name: "menu.dashboard",
  //   key: "dashboard",
  //   children: [
  //     {
  //       name: "menu.dashboard.workplace",
  //       key: "dashboard/workplace",
  //     },
  //   ],
  // },

  // {
  //   name: "Example",
  //   key: "example",
  // },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

export const generatePermission = (role: string) => {
  const actions = role === "admin" ? ["*"] : ["read"];
  const result = {};
  routes.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = (userPermission): [IRoute[], string] => {
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState<IRoute[]>([]);

  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return "";
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
