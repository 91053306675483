// 仅用于线上预览，实际使用中可以将此逻辑删除
import qs from "query-string";
import { isSSR } from "./is";

export type ParamsType = Record<string, any>;

export default function getUrlParams(): ParamsType {
  const params = qs.parseUrl(!isSSR ? window.location.href : "").query;
  const returnParams: ParamsType = {};
  Object.keys(params).forEach((p) => {
    if (params[p] === "true") {
      returnParams[p] = true;
    }
    if (params[p] === "false") {
      returnParams[p] = false;
    }
  });
  return returnParams;
}

export const getQuery = () => {
  let params :any= {}
  if (location.hash) {
    const arr = (location.hash || '')?.split('?')?.[1]?.split('&')??[]
    for (let i = 0; i < arr.length; i++) {
      const data = arr[i].split('=')
      if (data.length === 2) {
        params[data[0]] = data[1]
      }
    }
    params=Object.assign(params,qs.parseUrl(!isSSR ? window.location.href : "").query)
  } else {
    params = qs.parseUrl(!isSSR ? window.location.href : "").query;

  }


  return params
}