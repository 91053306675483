import "./style/global.less";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch } from "react-redux";
import { ConfigProvider, Modal } from "@arco-design/web-react";
import zhCN from "@arco-design/web-react/es/locale/zh-CN";
import enUS from "@arco-design/web-react/es/locale/en-US";
import { BrowserRouter, HashRouter, Switch, Route } from "react-router-dom";
import PageLayout from "./layout";
import { GlobalContext } from "./context";
import Login from "./pages/login";
import checkLogin from "./utils/checkLogin";
import changeTheme from "./utils/changeTheme";
import useStorage from "./utils/useStorage";
import "./mock";
import store from "./store";
import { permsList } from "./api/auth";
import { setPermissions } from "./store/reducers/appStore";



function Index(prop) {
  const [lang, setLang] = useStorage("arco-lang", "zh-CN");
  const [theme, setTheme] = useStorage("arco-theme", "light");
  let timer = null;
  function getArcoLocale() {
    switch (lang) {
      case "zh-CN":
        return zhCN;
      case "en-US":
        return enUS;
      default:
        return zhCN;
    }
  }
  useEffect(() => {
    if (checkLogin()) {
      console.log('登录成功');

    } else if (window.location.hash.replace(/#\//g, "") !== "login") {
      window.location.href = '/#/login'
    }


    checkHtmlChange()

    return () => {
      if (timer) {
        localStorage.removeItem('mainJs')
        clearInterval(timer)
      }
    }

  }, []);

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  const contextValue = {
    lang,
    setLang,
    theme,
    setTheme,
  };

  const checkHtmlChange = () => {
    localStorage.removeItem('mainJs')
    if (timer) {
      clearInterval(timer)
    }
    timer = setInterval(getVersionChange, 5 * 60 * 1000)
  }

  const getVersionChange = () => {
    fetch(window.location.origin)
      .then(response => response.text())
      .then(html => {
        // 在这里对获取到的 HTML 进行处理
        console.log(html);

        const regex = /main\.[a-zA-Z0-9]+\.js/;

        const match = html.match(regex);
        if (match) {
          const filename = match[0];
          console.log(filename); // 输出匹配到的文件名，例如 "main.e273511d.js"
          const localMainJs = localStorage.getItem('mainJs');
          if (!localMainJs) {
            console.log('存储mainJs', filename)
            localStorage.setItem('mainJs', filename);
          } else {
            if (filename != localMainJs) {
              localStorage.setItem('mainJs', filename);
              Modal.info({
                title: '系统更新',
                content:
                  '检测到系统有更新，请刷新页面加载到最新版本',
                onOk: () => {
                  window.location.href = window.location.origin
                },
              });

              // window.location.reload()
            }
          }
        } else {
          console.log('No match found');
        }

      })
      .catch(error => {
        // 处理错误
        console.error('Error:', error);
      });

  }

  return (
    <HashRouter>
      <ConfigProvider
        locale={getArcoLocale()}
        componentConfig={{
          Card: {
            bordered: false,
          },
          List: {
            bordered: false,
          },
          Table: {
            border: false,
          },
        }}
      >
        <Provider store={store}>
          <GlobalContext.Provider value={contextValue}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/" component={PageLayout} />
            </Switch>
          </GlobalContext.Provider>
        </Provider>
      </ConfigProvider>
    </HashRouter>
  );
}

ReactDOM.render(<Index />, document.getElementById("root"));
