import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { getQuery } from "@/utils/getUrlParams";
import useLocale from "@/utils/useLocale";
import { Button, Message, Space } from "@arco-design/web-react";
import { useSelector, useDispatch } from "react-redux";
import locale from "../locale";
import styles from "../style/index.module.less"
import { feishuLogin, loginOut } from "@/api/login";
import { setPermissions, setPermissionsBtn, setUserInfo, updateUserInfo } from "@/store/reducers/appStore";
import { permsList } from "@/api/auth";
function FeishuLogin(props) {
    const { code } = getQuery()
    const t = useLocale(locale);
    const dispatch = useDispatch();
    // 当前登录地址
    const redirect_uri = encodeURIComponent(`${location.origin}/#/login`)

    const [shows, setShows] = useState(false)
    // 扫码登录
    const QRLoginObj = useRef(null)
    const gotoUrl = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=cli_a37468bf1679900c&redirect_uri=${redirect_uri}&response_type=code`;
    const qcodeHandle = () => {
        document.getElementById('login_container').innerHTML = ''
        setShows(true)

    }
    useLayoutEffect(() => {
        document.getElementById('login_container').innerHTML = ''
        QRLoginObj.current = window.QRLogin({
            id: 'login_container',
            goto: gotoUrl,
            style: "width: 300px; height: 300px;border: 0; background-color: #E6F4F3; background-size: cover",
        });
    }, [shows])
    const handleMessage = (event) => {
        const origin = event.origin;

        if (QRLoginObj && QRLoginObj.current.matchOrigin(origin)
            && window.location.href.indexOf('/login') > -1
        ) {
            const loginTmpCode = event.data;
            window.location.href = `${gotoUrl}&tmp_code=${loginTmpCode}`;
        }
    }
    useEffect(() => {
        document.getElementById('login_container').innerHTML = ''


        window.addEventListener('message', handleMessage, false);
        return () => {
            window.removeEventListener('message', handleMessage, false);
        }
    }, [])



    // 飞书一键登录
    const freeHandle = () => {
        document.getElementById('login_container').innerHTML = ''
        window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a37468bf1679900c&response_type=code&redirect_uri=${redirect_uri}`
    }
    // 
    const getloginInfo = () => {
        localStorage.setItem('feishuCode', code)
        feishuLogin({ code: code }).then(res => {

            if (res.code == 200) {
                localStorage.setItem('token', res.data?.token ?? '')
                permsList().then(ress => {
                    if (ress.code == 200) {
                        Message.success('授权成功')
                        localStorage.setItem("userStatus", "login");
                        dispatch(setUserInfo(res.data))
                        const datas = {}
                        const btnDatas={}
                        for (const item of ress.data ?? []) {
                            if (item.indexOf(':')>-1) {
                                btnDatas[item] = true
                            }else{
                                datas[item] = ['*']
                            }

                        }
                        dispatch(setPermissions(datas))
                        dispatch(setPermissionsBtn(btnDatas))
                        props.history.push('/')
                    }
                })


            } else {
                Message.error('授权失败')
                loginOut()
            }

        })
    }

    const jumpHandle = () => {
        localStorage.setItem("userStatus", "login");
        props.history.push('/')
    }

    useEffect(() => {
        if (code) {
            getloginInfo()
        }
    }, [])
    return <div className={styles['feishu-login']}>
        <div className={styles["login-form-title"]}>{t["login.form.title"]}</div>
        <div id="login_container" className={styles['login_container']} style={{ display: shows ? 'block' : "none" }}></div>
        <Space direction="vertical">
            <div><Button type="primary" onClick={freeHandle}>飞书一键登录</Button></div>
            {/* <div><Button onClick={qcodeHandle}>飞书扫码登录</Button></div> */}
            {/* <div><Button status="warning" onClick={jumpHandle}>跳过飞书登录</Button></div> */}
        </Space>



    </div>
}
export default FeishuLogin