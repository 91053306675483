import React, { useContext, useEffect } from "react";
import {
  Tooltip,
  Input,
  Avatar,
  Select,
  Dropdown,
  Menu,
  Divider,
  Message,
  Button,
} from "@arco-design/web-react";
import {
  IconLanguage,
  IconNotification,
  IconSunFill,
  IconMoonFill,
  IconUser,
  IconSettings,
  IconPoweroff,
  IconExperiment,
  IconDashboard,
  IconInteraction,
  IconTag,
  IconDown,
} from "@arco-design/web-react/icon";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "@/store/reducers/appStore";
import { GlobalContext } from "@/context";
import useLocale from "@/utils/useLocale";
import Logo from "@/assets/logo.svg";
import MessageBox from "@/components/MessageBox";
import IconButton from "./IconButton";
import Settings from "../Settings";
import styles from "./style/index.module.less";
import defaultLocale from "@/locale";
import useStorage from "@/utils/useStorage";
import { generatePermission } from "@/routes";
import { getLoginVo, loginOut } from "@/api/login";
import { globalSelector, setUserInfo } from "@/store/reducers/appStore";
function Navbar({ show, history }: { show: boolean, history: any }) {
  const t = useLocale();
  const { userInfo, realName } = useSelector(globalSelector);
  const dispatch = useDispatch();

  const [_, setUserStatus] = useStorage("userStatus");
  const [role, setRole] = useStorage("userRole", "admin");

  const { setLang, lang, theme, setTheme } = useContext(GlobalContext);
  async function logout() {
    // await loginOut();
    localStorage.setItem('userInfo', '')
    loginOut().finally(()=>{
      localStorage.setItem('token','')
    })
    
    // localStorage.setItem('smell-key', '{}');
    dispatch(setUserInfo({}));
    setUserStatus("logout");
    
    history.push('/login')
  }

  function onMenuItemClick(key) {
    if (key === "logout") {
      logout();
    } else {
      Message.info(`You clicked ${key}`);
    }
  }

  // useEffect(() => {
  //   getLoginVo({}).then((res) => {
  //     dispatch(loginVo(res.obj))
  //   });
  // }, [role]);

  if (!show) {
    return (
      <div className={styles["fixed-settings"]}>
        <Settings
          trigger={
            <Button icon={<IconSettings />} type="primary" size="large" />
          }
        />
      </div>
    );
  }

  const handleChangeRole = () => {
    const newRole = role === "admin" ? "user" : "admin";
    setRole(newRole);
  };

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      {/* <Menu.SubMenu
        key="role"
        title={
          <>
            <IconUser className={styles["dropdown-icon"]} />
            <span className={styles["user-role"]}>
              {role === "admin"
                ? t["menu.user.role.admin"]
                : t["menu.user.role.user"]}
            </span>
          </>
        }
      >
        <Menu.Item onClick={handleChangeRole} key="switch role">
          <IconTag className={styles["dropdown-icon"]} />
          {t["menu.user.switchRoles"]}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="setting">
        <IconSettings className={styles["dropdown-icon"]} />
        {t["menu.user.setting"]}
      </Menu.Item>
      <Menu.SubMenu
        key="more"
        title={
          <div style={{ width: 80 }}>
            <IconExperiment className={styles["dropdown-icon"]} />
            {t["message.seeMore"]}
          </div>
        }
      >
        <Menu.Item key="workplace">
          <IconDashboard className={styles["dropdown-icon"]} />
          {t["menu.dashboard.workplace"]}
        </Menu.Item>
      </Menu.SubMenu> */}

      {/* <Divider style={{ margin: "4px 0" }} /> */}
      <Menu.Item key="logout">
        <IconPoweroff className={styles["dropdown-icon"]} />
        {t["navbar.logout"]}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <Logo />
          <div className={styles["logo-name"]}>后台系统</div>
        </div>
      </div>
      <ul className={styles.right}>
        {/* <li>
          <Input.Search
            className={styles.round}
            placeholder={t['navbar.search.placeholder']}
          />
        </li>
        <li>
          <Select
            triggerElement={<IconButton icon={<IconLanguage />} />}
            options={[
              { label: '中文', value: 'zh-CN' },
              { label: 'English', value: 'en-US' },
            ]}
            value={lang}
            triggerProps={{
              autoAlignPopupWidth: false,
              autoAlignPopupMinWidth: true,
              position: 'br',
            }}
            trigger="hover"
            onChange={(value) => {
              setLang(value);
              const nextLang = defaultLocale[value];
              Message.info(`${nextLang['message.lang.tips']}${value}`);
            }}
          />
        </li>
        <li>
          <MessageBox>
            <IconButton icon={<IconNotification />} />
          </MessageBox>
        </li>
        <li>
          <Tooltip
            content={
              theme === 'light'
                ? t['settings.navbar.theme.toDark']
                : t['settings.navbar.theme.toLight']
            }
          >
            <IconButton
              icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            />
          </Tooltip>
        </li> */}
        {/* <Settings /> */}
        {userInfo.name && (
          <li>
            <Dropdown droplist={droplist} position="br">
              <div className={styles.loginVo}>
                <Avatar size={32} style={{ cursor: "pointer" }}>
                  <img alt="avatar" src={userInfo.avatarUrl} />
                </Avatar>
                <div className={styles.realName}>
                  {userInfo.name}
                  <IconDown />
                </div>
              </div>
            </Dropdown>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Navbar;
