import React, { useEffect, useLayoutEffect, useRef } from "react";
import Footer from "@/components/Footer";
import Logo from "@/assets/logo.svg";
import { useDispatch } from "react-redux";
import LoginForm from "./form";
import LoginBanner from "./banner";
import styles from "./style/index.module.less";
import FeishuLogin from "./feishu";
import { logoutAction } from "@/store/reducers/appStore";

function Login(props) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutAction({}))
    document.body.setAttribute("arco-theme", "light");
  }, []);


  return (
    <div className={styles.container}>
      {/* <div className={styles.logo}>
        <Logo />
        <div className={styles['logo-text']}>后台系统</div>
      </div>
      <div className={styles.banner}>
        <div className={styles['banner-inner']}>
          <LoginBanner />
        </div>
      </div> */}
      <div className={styles.content}>
        <div className={styles["content-inner"]}>
          <FeishuLogin {...props}></FeishuLogin>
          {/* <LoginForm /> */}
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
}
Login.displayName = "LoginPage";

export default Login;
