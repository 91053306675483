export function isArray(val): boolean {
  return Object.prototype.toString.call(val) === "[object Array]";
}
export function isObject(val): boolean {
  return Object.prototype.toString.call(val) === "[object Object]";
}
export function isString(val): boolean {
  return Object.prototype.toString.call(val) === "[object String]";
}

export const isSSR = (function () {
  try {
    return !(typeof window !== "undefined" && document !== undefined);
  } catch (e) {
    return true;
  }
})();

export function isNumeric(value) {
  return /^\d+$/.test(value);
}

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

export const isValidDate = (dateString) => {
  return dateRegex.test(dateString);
};
