import axios, { AxiosError, AxiosResponse } from "axios";
import { getRuntimeConfig } from "@/config/runtimeConfig";
import { Message } from "@arco-design/web-react";
import { ToastError } from "@/utils/messageUtil";
const runtimeConfig = getRuntimeConfig(process.env["REACT_APP_ENV"] || "dev");
console.log(process.env["REACT_APP_ENV"]);

const instance = axios.create({
  baseURL:
    process.env["REACT_APP_ENV"] == "dev"
      ? "/api"
      : runtimeConfig.API_HOST + "/api",
  timeout: 60000,
});

instance.interceptors.request.use(
  (config: any) => {
    const c = { ...{ headers: {} }, ...config };
    if (localStorage.getItem("token")) {
      c.headers["authorization"] = localStorage.getItem("token");
    }
    return c;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 3.添加响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    const { data } = response;
    // console.log(response);
    // if (response.status == 401 || data?.code == 401) {
    //   ToastError(data);
    //   window.location.href = "/#/login";
    //   throw new Error("authorization error");
    // }
    return Promise.resolve(data);
  },
  (error: AxiosError) => {
    if (error.response.status === 401) {
      // 处理 401 错误，例如重新登录
      console.log("Unauthorized", error.response);
      Message.error("登录失效，请重新登录");
      window.location.href = "/#/login";
      throw new Error("authorization error");
    }
    if (error.response.status === 403) {
      console.log("403", error.response);
      Message.error("你无权限操作哦，请联系管理员！");
    }
    return Promise.reject(error);
  }
);
export default instance;
