import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { subscribeSpectaculars } from "@/api/appointmentApi";
import { AppDispatch, RootState } from ".."; // 在 store/index.ts 中声明的类型
import dayjs from "dayjs";

export interface ShopInfo {
  label: string;
  value: string | number;
  extra?: {
    storeSettings?: number;
  };
}
export interface CalendarType {
  value: "week" | "day";
  label: "周" | "日";
}

// 创建坑位的参数信息
export interface CreateHole {
  id?: number;
  date?: string;
  startTime?: string;
  endTime?: string;
  employee?: Employee;
  type?: number; // 坑位类型
  stroeArea?: StoreArea;
}

// 拖动生成的方块信息
export interface Boxes {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  yIndex?: number;
  xIndex?: number;
  len?: number;
  startTime?: string;
  endTime?: string;
}

// 服务区人员
export interface Employee {
  employeeName: string;
  status?: number;
  userId: string;
  attendance?: null | number;
  avatarUrl?: string;
}

export interface StoreArea {
  status: number;
  storeAreaId: number;
  storeAreaName: string;
}

// 养发师状态
export enum EmployeeStatus {
  "空闲中",
  "占用中",
  "服务区不匹配",
  "已请假",
  "休息中",
  "未排班",
}

export enum AreaStatusEnum {
  "空闲中",
  "占用中",
  "养发师不匹配",
  "禁用中",
}

export enum OrderStatusEnum {
  "待到店",
  "已到店",
  "已离店",
}

export interface PitOsitionReserveOrderVo {
  reserveOrderId: number;
  pitositionId: number;
  employeeName: string;
  userId: string;
  userRealName: string;
  userPhone: string;
  userLevel: string;
  reserveOrderDate: string;
  reserveOrderDateTime: string;
  areaName: string;
  serveName: string;
  allServiceTime: string;
  weeks: string;
  orderStatus?: number;
  serveVoList?: {
    serveId: number;
    serveName: string;
    serveType: number;
  }[];
  orderType: number; // 0待核销 1已核销 2已取消
  labelId: number;
  serviceEndTime?: string;
  incenseId?: number; // 香型id
  leaveTime?: string;
  categoryName?: string;
}

// 坑位单
export interface PitOsitionVo {
  id: number;
  userId?: string;
  employeeName?: string;
  serviceId?: number;
  serviceName?: string;
  startDateTime?: string;
  endDateTime?: string;
  status?: number;
  type?: number;
  attendance?: null | number;
  serviceStatus?: number;
  pitOsitionReserveOrderVo?: PitOsitionReserveOrderVo;
}

export interface TabItem {
  tabName: string;
  id: string;
  pitOsitionVoList?: PitOsitionVo[];
  flag?: number;
}
export interface UserDrawer {
  userId?: string | number;
  reserveId?: string | number;
  userDrawerShow?: boolean;
  pitOsitionVo?: PitOsitionVo;
  tabKey?: string;
}
export interface AppointmentState {
  type?: number; // 0 、1 养发师 or 服务区
  shopInfo?: ShopInfo; // 选中店铺
  shopList?: ShopInfo[]; // 店铺列表
  holeBillChecked?: boolean; // 是否选中坑位单
  appointBillChecked?: boolean; // 是否选中预约单
  holeRangeOption?: Array<number>; // 坑位时长设置
  calendarType?: "week" | "day"; // 日历视图类型 周 or  日
  // startDate?: string; // 开始时间
  // endDate?: string; // 结束时间
  createHole?: CreateHole; // 创建坑位的参数对象
  bussinessStartTime?: string; // 开始营业时间
  bussinessEndTime?: string; // 结束营业时间
  showCreactModal?: boolean; // 显示创建坑位
  boxes?: Boxes[]; // 拖动生成的方块
  employeeList?: Employee[]; // 养发师列表
  areaList?: StoreArea[];
  tabList?: TabItem[]; // 表头值
  weekStareDate?: string; // 周视图开始日期
  weekEndDate?: string; // 周视图结束日期
  date?: string; // 日视图的日期
  pitOsitionId?: number; // 当前要高亮的坑位单
  showPitEdit?: boolean; // 是否在编辑坑位单
  appointPopupVisible?: boolean; // 显示预约表单
  showPitDetail?: boolean; // 是否在显示坑位详情弹出
  showAppointDetail?: boolean; // 显示预约单详情
  pitId?: number; // 要弹出框的坑位id
  loading?: boolean;
  smell?: object;
  userDrawerShow?: boolean; //用戶抽屜弹出
  userDrawerInfo?: UserDrawer; //用戶抽屜id等信息
  currentBussinessStartTime?: string;
  currentBussinessEndTime?: string;
  newUserId?: string;
  addUserVisable?: boolean;
  autoCreateModalVisable?: boolean;
}

const initHole = {
  date: "",
  startTime: null,
  endTime: null,
  employee: null,
  type: 0,
  stroeArea: null,
};

const initialState: AppointmentState = {
  type: 0,
  shopInfo: { label: "", value: "" },
  shopList: [],
  holeBillChecked: true,
  appointBillChecked: true,
  holeRangeOption: [75, 90, 105, 120, 150, 180],
  calendarType: "day",
  // startDate: "",
  // endDate: "",
  weekStareDate: "", // 周视图开始日期
  weekEndDate: "", // 周视图结束日期
  date: "", // 日视图的日期
  createHole: initHole,
  bussinessEndTime: "22:30",
  bussinessStartTime: "09:30",
  currentBussinessStartTime: "09:30",
  currentBussinessEndTime: "22:30",
  showCreactModal: false,
  boxes: [],
  tabList: [],
  areaList: [],
  pitOsitionId: null,
  showPitEdit: false,
  appointPopupVisible: false,
  showPitDetail: false,
  showAppointDetail: false,
  pitId: -1,
  loading: false,
  smell: {},
  userDrawerShow: false,
  userDrawerInfo: {
    reserveId: "",
    userId: "",
  },
  newUserId: "",
  addUserVisable: false,
  autoCreateModalVisable: false,
};

export const getTableData = createAsyncThunk(
  "appoint/subscribeSpectaculars",
  async (params: any, action) => {
    try {
      const { appoint } = action.getState() as any;

      const { calendarType, weekStareDate, weekEndDate, date, shopInfo, type } =
        appoint;

      // console.log("getState.weekEndDate", action.getState());

      const startDate = calendarType == "week" ? weekStareDate : date;
      const endDate = calendarType == "week" ? weekEndDate : date;
      const p = {
        endDateTime: `${endDate} 23:59:59`,
        startDateTime: `${startDate} 00:00:00`,
        storeId: shopInfo?.value || "",
        tab: type,
        ...params,
      };
      const res = await subscribeSpectaculars(p);
      if (res.code == 200) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

const appointmentSlice = createSlice({
  name: "appoint", // 生成 Action type 的前缀，例如：counter/increment
  initialState: initialState,
  reducers: {
    changeType: (state, action: PayloadAction<0 | 1>) => {
      state.type = action.payload;
    },
    updateShopList: (state, action: PayloadAction<Array<ShopInfo>>) => {
      state.shopList = action.payload;
    },
    setCurrentShop: (state, action: PayloadAction<any>) => {
      const target = state?.shopList.filter(
        (it) => it.value == action.payload
      )[0];
      if (target)
        state.shopInfo = {
          ...target,
          extra: { storeSettings: target.extra?.storeSettings || 120 },
        };
    },
    setCalendarType: (state, action: PayloadAction<"week" | "day">) => {
      state.calendarType = action.payload;
    },

    setAppointmentState: (state, action: PayloadAction<AppointmentState>) => {
      return { ...state, ...action.payload };
    },
    setCreactModalVisible: (state, action: PayloadAction<boolean>) => {
      state.showCreactModal = action.payload;
    },
    setCreateHole: (state, action: PayloadAction<CreateHole>) => {
      state.createHole = { ...state.createHole, ...action.payload };
    },
    cleanCreateHole: (state, action) => {
      state.createHole = { ...initHole };
    },
    setBoxes: (state, action: PayloadAction<Boxes[]>) => {
      state.boxes = action.payload;
    },
    setHoleBillChecked: (state, action: PayloadAction<boolean>) => {
      state.holeBillChecked = action.payload;
    },
    setAppointBillChecked: (state, action: PayloadAction<boolean>) => {
      state.appointBillChecked = action.payload;
    },
    setTabList: (state, action: PayloadAction<TabItem[]>) => {
      state.tabList = action.payload;
    },

    setAppointPopupVisible: (state, action: PayloadAction<boolean>) => {
      state.appointPopupVisible = action.payload;
    },

    setShowPitDetail: (state, action: PayloadAction<boolean>) => {
      state.showPitDetail = action.payload;
    },
    setPitId: (state, action: PayloadAction<number>) => {
      state.pitId = action.payload;
    },
    dismissAll: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showAppointDetail: false,
        showPitDetail: false,
        appointPopupVisible: false,
        showPitEdit: false,
      };
    },
    addSmell: (state, action: PayloadAction<{ reserveOrderId; id }>) => {
      const { reserveOrderId, id } = action.payload;
      state.smell = { ...state.smell, [reserveOrderId]: id };
      localStorage.setItem("smell-key", JSON.stringify(state.smell));
    },

    deleteSmell: (state, action: PayloadAction<number>) => {
      delete state.smell[action.payload];
      localStorage.setItem("smell-key", JSON.stringify(state.smell));
    },
    setSmell: (state, action: PayloadAction<object>) => {
      state.smell = action.payload;
    },
    setUserDrawer: (state, action: PayloadAction<UserDrawer>) => {
      const { userDrawerShow } = action.payload;
      return {
        ...state,
        userDrawerInfo: Object.assign(
          { userId: "", reserveId: "" },
          action.payload
        ),
        userDrawerShow,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTableData.fulfilled, (state, action: PayloadAction<any>) => {
        const {
          bussinessStartTime,
          bussinessEndTime,
          pitOsitionListVo,
          currentBussinessEndTime,
          currentBussinessStartTime,
        } = action.payload;
        return {
          ...state,
          bussinessStartTime,
          bussinessEndTime,
          tabList: pitOsitionListVo,
          loading: false,
          currentBussinessEndTime,
          currentBussinessStartTime,
        };
      })
      .addCase(getTableData.pending, (state, action: PayloadAction<any>) => {
        state.loading = true;
      })
      .addCase(getTableData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
      });
  },
});

export const {
  changeType,
  updateShopList,
  setCurrentShop,
  setCalendarType,
  setAppointmentState,
  setCreactModalVisible,
  setCreateHole,
  setBoxes,
  setHoleBillChecked,
  setAppointBillChecked,
  setTabList,
  cleanCreateHole,
  setAppointPopupVisible,
  setShowPitDetail,
  setPitId,
  dismissAll,
  addSmell,
  deleteSmell,
  setSmell,
  setUserDrawer,
} = appointmentSlice.actions;

// Selector，作为 useSelector 读取数据的函数参数
export const appointmentSelector = (state: RootState) => state.appoint;

// Reducer，真正执行修改 state 的纯函数
export default appointmentSlice.reducer;
