import request from "@/request";
import qs from "qs"

export const getLoginVo = (params) => {
  return request({
    url: "/api/getLoginVo",
    method: "get",
    params,
  });
};

export const loginOut = () => {
  return request({
    url: "/fyys/admin/auth/employee/logout",
    method: "post",
  });
};


// 飞书登录
export const feishuLogin = (data) => {
  return request({
    url: "/fyys/admin/auth/employee/login",
    method: "post",
    headers:{
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data:qs.stringify(data)
  });
};

