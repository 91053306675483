import request from "@/request";
export const getBedlist = (params = {}) => {
  return request({
    url: "/api/appointment/bedlist",
    method: "get",
    params,
  });
};

export const getHairdresser = (params = {}) => {
  return request({
    url: "/api/appointment/hairdresser",
    method: "get",
    params,
  });
};

// 当前用户门店
export const getShopList = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/storeList",
    method: "post",
    data: params,
  });
};

// 查询坑位单
export const subscribeSpectaculars = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/subscribeSpectaculars",
    method: "post",
    data: params,
  });
};
// 查询养发师
export const getEmployeeList = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/employeeList",
    method: "post",
    data: params,
  });
};

// 坑位设置
export const updateStoreSettings = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/updateStoreSettings",
    method: "post",
    data: params,
  });
};

// 保存坑位单
export const savePitOsition = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/save",
    method: "post",
    data: params,
  });
};

// 坑位单是否保存
export const checkPitOsitionSave = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/checkPitOsitionSave",
    method: "post",
    data: params,
  });
};

// 删除
export const pitOsitionDelete = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/delete",
    method: "post",
    data: params,
  });
};

// 编辑坑位单
export const pitOsitionUpdate = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/update",
    method: "post",
    data: params,
  });
};

// 坑位单修改校验
export const checkpitositionUpdate = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/checkpitositionUpdate",
    method: "post",
    data: params,
  });
};

// 查询服务区
export const getStoreAreaList = (params = {}) => {
  return request({
    url: "/fyys/admin/pitOsition/storeAreaList",
    method: "post",
    data: params,
  });
};

//服务项目列表
export const serveList = (data) => {
  return request({
    url: "/fyys/admin/serve/list",
    method: "post",
    data,
  });
};

// 坑位单查询
export const pitOsitionFindList = (data) => {
  return request({
    url: "/fyys/admin/pitOsition/pitOsitionFindList",
    method: "post",
    data,
  });
};

// 查询用户下拉
export const userPullDownList = (data) => {
  return request({
    url: "/fyys/admin/user/userPullDownList",
    method: "post",
    data,
  });
};

// 查询服务
export const queryServeByArea = (data) => {
  return request({
    url: "/fyys/admin/serve/queryServeByArea",
    method: "post",
    data,
  });
};

// 保存预约单
export const saveReserve = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/saveReserve",
    method: "post",
    data,
  });
};

// 删除预约单
export const reserveDelete = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/reserveDelete",
    method: "post",
    data,
  });
};

// 更新
export const reserveUpdate = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/reserveUpdate",
    method: "post",
    data,
  });
};

// 状态流转
export const reserveUpdateStatus = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/reserveUpdateStatus",
    method: "post",
    data,
  });
};

// 查询预约单资产
export const reserveConsumptionDetails = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/reserveConsumptionDetails",
    method: "post",
    data,
  });
};

// 预约单核销结算
export const reserveServeOrderWriteOff = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/reserveServeOrderWriteOff",
    method: "post",
    data,
  });
};

// 用户抽屉
export const reserveDrawer = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/reserveDrawer",
    method: "post",
    data,
  });
};

export const selectAllARNameList = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/selectAllARNameList",
    method: "post",
    data,
  });
};

export const reserveRemarkUpdate = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/reserveRemarkUpdate",
    method: "post",
    data,
  });
};

export const insertARByName = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/insertARByName",
    method: "post",
    data,
  });
};

export const drawerUserUpdate = (data) => {
  return request({
    url: "/fyys/admin/user/drawerUserUpdate",
    method: "post",
    data,
  });
};

export const reserveIncenseUpdate = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/reserveIncenseUpdate",
    method: "post",
    data,
  });
};

export const batchSave = (data) => {
  return request({
    url: "/fyys/admin/pitOsition/batchSave",
    method: "post",
    data,
  });
};

export const batchDeleteByDate = (data) => {
  return request({
    url: "/fyys/admin/pitOsition/batchDeleteByDate",
    method: "post",
    data,
  });
};

// 自动建坑用-服务人员下拉列表
export const employeePullDownList = (data) => {
  return request({
    url: "/fyys/admin/employee/employeePullDownList",
    method: "post",
    data,
  });
};

export const storeAreaPullDownList = (data) => {
  return request({
    url: "/fyys/admin/serve/storeAreaPullDownList",
    method: "post",
    data,
  });
};

// 
export const expirationTime = (data) => {
  return request({
    url: "/fyys/admin/reserveOrder/expirationTime/reserveUpdate",
    method: "post",
    data,
  });
};